<template>
	<div :class="layoutContainerClass" @click="onDocumentClick">
		<AppTopBar :horizontal="menuMode==='horizontal'" :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem" :mobileTopbarActive="mobileTopbarActive" @topbar-mobileactive="onTopbarMobileButtonClick"
			@menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick" @topbaritem-click="onTopbarItemClick" @rightpanel-button-click="onRightPanelButtonClick"
			:searchActive="searchActive" @search-toggle="onSearchToggle" @search-click="onSearchClick" @search-hide="onSearchHide"></AppTopBar>

			<div class="menu-wrapper">
				<div class="layout-menu-container" @click="onMenuClick">
						<AppInlineMenu v-if="false" v-model:active="inlineMenuTopActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="top" :menuMode="menuMode"></AppInlineMenu>
						<AppMenu :model="menu" :menuMode="menuMode" :active="menuActive" :mobileMenuActive="mobileMenuActive" :isSlimOrHorItemClick="isSlimOrHorItemClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
						<AppInlineMenu v-if="false" v-model:active="inlineMenuBottomActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="bottom" :menuMode="menuMode"></AppInlineMenu>
				</div>
			</div>

		<div class="layout-main">

			<AppBreadcrumb v-if="false"></AppBreadcrumb>

			<div class="layout-content">
				<router-view :key="$route.fullPath" />
			</div>

			<AppFooter :layoutMode="layoutMode" />
		</div>

		<AppConfig v-if="false" :menuMode="menuMode" @menu-mode-change="onMenuModeChange" @layout-mode-change="onLayoutModeChange" @menu-theme="onMenuTheme"
				:layoutMode="d_layoutMode" @topbar-theme="onTopbarThemeChange"
				v-model:inlineMenuPosition="inlineMenuPosition" @inlinemenu-change="onInlineMenuPositionChange"
				:theme="theme" :themes="themes" @theme-change="changeTheme" :menuTheme="d_menuTheme" :menuThemes="menuThemes"
				:topbarTheme="d_topbarTheme" :topbarThemes="topbarThemes"></AppConfig>

		<AppRightPanel :expanded="rightPanelActive" @content-click="onRightPanelClick" @hide="onHideClick"></AppRightPanel>
		<div v-if="mobileMenuActive" class="layout-mask modal-in"></div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppInlineMenu from './AppInlineMenu.vue';
import AppRightPanel from './AppRightPanel.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import EventBus from './event-bus';

export default {
	emits: ['layout-mode-change', 'menu-theme', 'menuTheme', 'topbar-theme', 'topbarTheme', 'layoutModeChange'],
	props: {
		topbarTheme: String,
		menuTheme: String,
		layoutMode: String
	},
    data() {
        return {
			crmService: null,
			d_topbarTheme: this.topbarTheme,
			d_menuTheme: this.menuTheme,
			d_layoutMode: this.layoutMode,
			mobileTopbarActive: false,
			mobileMenuActive: false,
			search: false,
			searchClick: false,
			searchActive: false,
			menuMode: 'horizontal',
			inlineMenuClick: false,
			inlineMenuPosition: 'bottom',
			inlineMenuTopActive: false,
			inlineMenuBottomActive: false,
			overlayMenuActive: false,
			rotateMenuButton: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			isSlimOrHorItemClick: false,
			darkMenu: false,
			theme: 'blue',
			themes: [
				{name: 'indigo', color: '#2f8ee5'},
				{name: 'pink', color: '#E91E63'},
				{name: 'purple', color: '#9C27B0'},
				{name: 'deeppurple', color: '#673AB7'},
				{name: 'blue', color: '#2196F3'},
				{name: 'lightblue', color: '#03A9F4'},
				{name: 'cyan', color: '#00BCD4'},
				{name: 'teal', color: '#009688'},
				{name: 'green', color: '#4CAF50'},
				{name: 'lightgreen', color: '#8BC34A'},
				{name: 'lime', color: '#CDDC39'},
				{name: 'yellow', color: '#FFEB3B'},
				{name: 'amber', color: '#FFC107'},
				{name: 'orange', color: '#FF9800'},
				{name: 'deeporange', color: '#FF5722'},
				{name: 'brown', color: '#795548'},
				{name: 'bluegrey', color: '#607D8B'}
			],
			menuThemes: [
				{name: 'light', color: '#FDFEFF'},
				{name: 'dark', color: '#434B54'},
				{name: 'indigo', color: '#1A237E'},
				{name: 'bluegrey', color: '#37474F'},
				{name: 'brown', color: '#4E342E'},
				{name: 'cyan', color: '#006064'},
				{name: 'green', color: '#2E7D32'},
				{name: 'deeppurple', color: '#4527A0'},
				{name: 'deeporange', color: '#BF360C'},
				{name: 'pink', color: '#880E4F'},
				{name: 'purple', color: '#6A1B9A'},
				{name: 'teal', color: '#00695C'}
			],
			topbarThemes: [
				{name: 'lightblue', color: '#2E88FF'},
				{name: 'dark', color: '#363636'},
				{name: 'white', color: '#FDFEFF'},
				{name: 'blue', color: '#1565C0'},
				{name: 'deeppurple', color: '#4527A0'},
				{name: 'purple', color: '#6A1B9A'},
				{name: 'pink', color: '#AD1457'},
				{name: 'cyan', color: '#0097A7'},
				{name: 'teal', color: '#00796B'},
				{name: 'green', color: '#43A047'},
				{name: 'lightgreen', color: '#689F38'},
				{name: 'lime', color: '#AFB42B'},
				{name: 'yellow', color: '#FBC02D'},
				{name: 'amber', color: '#FFA000'},
				{name: 'orange', color: '#FB8C00'},
				{name: 'deeporange', color: '#D84315'},
				{name: 'brown', color: '#5D4037'},
				{name: 'grey', color: '#616161'},
				{name: 'bluegrey', color: '#546E7A'},
				{name: 'indigo', color: '#3F51B5'}
			],
			rightPanelActive: false,
			menuActive: true,
            menu: [
				/*
				{
					label: 'PANOLAR', 
					icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Satış Panosu', icon: 'pi pi-fw pi-home', to: '/' },
						{label: 'Satış Takip', icon: 'pi pi-fw pi-home', to: '/favorites/dashboardanalytics' }
					]
				},
				*/
				{
					label: 'FİRMA & İLGİLİ KİŞİ', 
					icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Firmalar', icon: 'pi pi-fw pi-building', to: '/accountlist' },
						{label: 'İlgili Kişiler', icon: 'pi pi-fw pi-user', to: '/contactlist' },
					]
				},
				{
					label: 'AKTİVİTELER', 
					icon: 'pi pi-fw pi-calendar-plus',
					items: [
						{label: 'Randevular', icon: 'pi pi-fw pi-calendar-plus', to: '/appointmentlist' },
						{label: 'Telefon Görüşmeleri', icon: 'pi pi-fw pi-phone', to: '/phonecalllist' },
					]
				},
				{
					label: 'PROJE & SİPARİŞ', 
					icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Satış Projeleri', icon: 'pi pi-fw pi-money-bill', to: '/opportunitylist' },
						{label: 'Teklifler', icon: 'pi pi-fw pi-money-bill', to: '/quotelist' },
						{label: 'Siparişler', icon: 'pi pi-fw pi-money-bill', to: '/salesorderlist' },
						{label: 'Hedefler', icon: 'pi pi-fw pi-chart-line', to: '/bm_hedefayrintilist' },
					]
				},
				{
					label: 'RAPORLAR', 
					icon: 'pi pi-fw pi-chart-line',
					items: [
						//{label: 'Yönetim Dashboard', icon: 'pi pi-fw pi-chart-line', to: '/dashboard_yonetim1' },
						{label: 'Yönetim Dashboard', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('YonetimDashboard', this.dashboardYonetimDefaultDatesParameter, true); }},
						{label: 'Hedef Başarı Analizi', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('HedefBasariAnalizi2', null); }},
						{label: 'Kazanılan Projeler - Bölgeye Göre', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('Dashboard_KazanilanProjeler',null); }},
						{label: 'Kazanılan Projeler - Markaya Göre', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('Dashboard_KazanilanProjelerMarka',null); }},
						{label: 'Siparişler - Bölgeye Göre', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('Dashboard_Satislar',null); }},
						{label: 'Siparişler - Markaya Göre', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('Dashboard_SatislarMarka',null); }},
						{label: 'Personel Analizi', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('Dashboard_PersonelAnalizi',null); }},
					]
				},
				{
					label: 'ÜRÜNLER', 
					icon: 'pi pi-fw pi-home',
					items: [
						{label: 'Tezgahlar', icon: 'pi pi-fw pi-building', to: '/bm_unitelist' },
						{label: 'Ürünler', icon: 'pi pi-fw pi-building', to: '/productlist' },
					]
				},
				{
					label: 'SERVİS',
					icon: 'pi pi-fw pi-exclamation-circle',
					items: [
						{label: 'Servis Panosu', icon: 'pi pi-fw pi-chart-line', to: '/dashboard_servis'},
						{label: 'Servis Personel Verimlilik', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('ServisPersonelVerimlilik',null); }},
						{label: 'Servis Raporu Personel Analizi', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('ServisRaporuPersonelAnalizi',null); }},
						{label: 'Servis Talepleri', icon: 'pi pi-fw pi-exclamation-circle', to: '/incidentlist'},
						{label: 'Servis Raporları', icon: 'pi pi-fw pi-exclamation-circle', to: '/serviceappointmentlist'},
						{label: 'Sözleşmeler', icon: 'pi pi-fw pi-exclamation-circle', to: '/bm_sozlesmelerlist' },
						{label: 'Tesis Ekipman', icon: 'pi pi-fw pi-exclamation-circle', to: '/equipmentlist' },
					]
				},
				{
					label: 'YEDEK PARÇA',
					icon: 'pi pi-fw pi-exclamation-circle',
					items: [
						{label: 'Y.P. Teklif / Sipariş', icon: 'pi pi-fw pi-exclamation-circle', to: '/bm_yedekparcateklifsiparislist'},
						{label: 'Yedek Parçalar', icon: 'pi pi-fw pi-exclamation-circle', to: '/bm_yedekparcalist'},
						{label: 'Y.P. Haftalık Analiz', icon: 'pi pi-fw pi-chart-line', command: () => { this.GotoReport('YedekParcaTeklifSiparisAnaliz',null); }},
					]
				},
				{
					label: 'OTOMASYON',
					icon: 'pi pi-fw pi-sitemap',
					items: [
						{label: 'Otomasyon Projesi', icon: 'pi pi-fw pi-sitemap', to: '/bm_otomasyonprojesilist'},
						{label: 'Otomasyon Servis', icon: 'pi pi-fw pi-sitemap', to: '/bm_otomasyonservislist'},
						{label: 'Günlük Raporlama Excel', icon: 'pi pi-fw pi-sitemap', command: () => { this.GotoUrl('https://formmakina-my.sharepoint.com/:x:/r/personal/tayfun_celik_formservis_com_tr/Documents/Masa%C3%BCst%C3%BC/EXCEL/G%C3%BCnl%C3%BCk%20Raporlama%20-%20Proje.xlsx?d=wa78e5aaac9eb4d149bdce428b44dfa29&csf=1&web=1&e=psS92m'); }},
					]
				},
            ]
        }
    },
    watch: {
        $route() {
			this.menuActive = this.isStatic() && !this.isMobile();
            this.$toast.removeAllGroups();
        },
		profileData(){
			this.modulYetkiAyarla();
		},
		topbarTheme(newValue) {
			this.d_topbarTheme = newValue;
		},
		menuTheme(newValue) {
			this.d_menuTheme = newValue;
		},
		layoutMode(newValue) {
			this.d_layoutMode = newValue;
		}
    },
    methods: {
		checkPermissionModul(modulName){
			if (this.profileData) {
				
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == modulName);
					if (filtered.length > 0) {
						return true;
					} else {
						return false;
					}
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		modulYetkiAyarla(){
			for (let index = 0; index < this.menu.length; index++) {
				const element = this.menu[index];

				let anaMenuGorunsunmu = false;
				for (let indexItem = 0; indexItem < element.items.length; indexItem++) {
					const elementMenuItem = element.items[indexItem];
					elementMenuItem.visible = this.checkPermissionModul(elementMenuItem.label);
					if (elementMenuItem.visible == true) {
						anaMenuGorunsunmu = true;
					}
				}

				element.visible = anaMenuGorunsunmu;
			}
		},
		onDocumentClick() {
			if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

			if(!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if(!this.menuClick) {
				if(this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					this.isSlimOrHorItemClick = false;
					EventBus.emit('reset-active-index');
				}

				if (this.isOverlay()) {
                    this.menuActive = false;
                }

				this.hideOverlayMenu();
				this.unblockBodyScroll();
			}

			if(!this.rightPanelClick) {
				this.rightPanelActive = false;
			}

			if(!this.inlineMenuClick) {
				this.inlineMenuTopActive = false;
				this.inlineMenuBottomActive = false;
			}

			this.topbarItemClick = false;
			this.menuClick = false;
			this.rightPanelClick = false;
			this.searchClick = false;
			this.inlineMenuClick = false;
        },
		onSearchToggle() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
		onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
		isHorizontal() {
			return this.menuMode === 'horizontal';
		},
		isSlim() {
			return this.menuMode === 'slim';
		},
		isOverlay() {
			return this.menuMode === 'overlay';
		},
		isStatic() {
			return this.menuMode === 'static';
		},
		isDesktop() {
			return window.innerWidth > 991;
		},
		isMobile() {
			return window.innerWidth <= 991;
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.mobileMenuActive = false;
		},
		onMenuButtonClick(event){
			this.menuClick = true;
			this.menuActive = !this.menuActive;
			this.topbarMenuActive = false;
			this.topbarRightClick = true;
			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if(!this.isDesktop()) {
				this.mobileMenuActive = !this.mobileMenuActive;
				if (this.mobileMenuActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event){
			this.topbarItemClick = true;

			if(this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onTopbarMobileButtonClick(event) {
			this.mobileTopbarActive = !this.mobileTopbarActive;
			event.preventDefault();
		},
		onRightPanelButtonClick(event){
			this.rightPanelClick = true;
			this.rightPanelActive = !this.rightPanelActive;

			event.preventDefault();
		},
		onRightPanelClick(){
			this.rightPanelClick = true;
		},
		onHideClick(expanded){
			this.rightPanelActive = expanded;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onRootMenuItemClick(event) {
            if(event.isSameIndex) {
                this.isSlimOrHorItemClick = false;
            }
            else {
                this.isSlimOrHorItemClick = true;
            }
			this.menuActive = !this.menuActive;
		},
		onMenuItemClick(event) {
			if(!event.item.items) {
                this.isSlimOrHorItemClick = false;
				this.hideOverlayMenu();
				EventBus.emit('reset-active-index');
			}

			if(!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}
		},
		onMenuModeChange(menuMode) {
			this.menuMode = menuMode;
			this.overlayMenuActive = false;
			
			if(menuMode === 'static') {
				this.menuActive = true;
			}

			if(menuMode === 'horizontal') {
				this.inlineMenuPosition = 'bottom';
			}
		},
		onLayoutModeChange(menuColor) {
			this.$emit('layout-mode-change', menuColor);

			const layoutLink = document.getElementById('layout-css');
			const layoutHref = 'layout/css/layout-' + menuColor + '.css';
			this.replaceLink(layoutLink, layoutHref);

			const themeLink = document.getElementById('theme-css');
			const urlTokens = themeLink.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = 'theme-' + menuColor + '.css';
			const newURL = urlTokens.join('/');

			this.replaceLink(themeLink, newURL, () => {
				this.$appState.isNewThemeLoaded = true;
			});
		},
		onInlineMenuPositionChange(position) {
			this.inlineMenuPosition = position;
		},
		onChangeInlineMenu(e, key) {
			if(key === 'top') {
				if(this.inlineMenuBottomActive) {
					this.inlineMenuBottomActive = false;
				}
				this.inlineMenuTopActive = !this.inlineMenuTopActive;
			}
			if(key === 'bottom') {
				if(this.inlineMenuTopActive) {
					this.inlineMenuTopActive = false;
				}
				this.inlineMenuBottomActive = !this.inlineMenuBottomActive;
			}

			this.inlineMenuClick = true;
			
		},
		changeTheme(theme) {
			this.theme = theme;
			this.changeStyleSheetUrl('theme-css', theme);
		},
		onTopbarThemeChange(theme) {
			this.$emit('topbar-theme', theme);
		},
		onMenuTheme(menuTheme) {
			this.$emit('menu-theme', menuTheme);
		},
		changeStyleSheetUrl(id, value) {
			const element = document.getElementById(id);
			const urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 2] = value;
			const newURL = urlTokens.join('/');
			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href, callback) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);

				if (callback) {
					callback();
				}
			});
		},
		blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
		GotoReport(Name, ParamName, customParameters){
			if (customParameters) {
				if (customParameters == true) {
					const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName;
					window.open(reportUrl, 'asd', '_blank');
				}
			}
			else if (ParamName) {
				const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=" + this.entityId;
				window.open(reportUrl, 'asd', '_blank');
			}
			else {
				const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name;
				window.open(reportUrl, 'qwe', '_blank');
			}
		},
		GotoReportUserId(Name, ParamName) {
			console.log(this.profileData);
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=" + this.profileData.ID;
			window.open(reportUrl, 'asd', '_blank');
		},
		GotoUrl(Url){
			if (Url) {
				window.open(Url, 'asd', '_blank');
			}
		},
		formatDateForReport(date){
			if (date) {
				return date.getFullYear() + '-' + (parseInt(date.getMonth()) + 1) + '-' + date.getDate();
			}
			else {
				return "";
			}
		}
    },
    computed: {
		isServisYonetici(){
			const isSeviye = (this.profileData.guvenlikSeviyeleri.indexOf("Servis Yönetici") > -1);
			return isSeviye;
		},
		dashboardYonetimDefaultDatesParameter(){
			let reportDates = [];
			const today = new Date();
			const monday = new Date(today.setDate(today.getDate() - today.getDay() + 1));
			reportDates.push(monday);
			reportDates.push(new Date());

			const dateStr1 = this.formatDateForReport(reportDates[0]);
			const dateStr2 = this.formatDateForReport(reportDates[1]);
			
			return "date1=" + dateStr1 + "&date2=" + dateStr2;
		},
		layoutContainerClass() {
            return [
				'layout-wrapper', 
				'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme, 
				{
					'layout-menu-static': this.menuMode === 'static',
					'layout-menu-overlay': this.menuMode === 'overlay',
					'layout-menu-overlay-active': this.overlayMenuActive,
					'layout-menu-slim': this.menuMode === 'slim',
					'layout-menu-horizontal': this.menuMode === 'horizontal',
					'layout-menu-active': this.menuActive,
					'layout-menu-mobile-active': this.mobileMenuActive,
					'layout-topbar-mobile-active': this.mobileTopbarActive,
					'layout-rightmenu-active': this.rightPanelActive,
					'layout-rtl': this.$appState.RTL,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}
			];
        },
		profileData(){
			return this.$store.getters.getProfile;
		},
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppInlineMenu': AppInlineMenu,
        'AppRightPanel': AppRightPanel,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
        'AppBreadcrumb': AppBreadcrumb
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
