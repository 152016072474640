import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_SERVICEURL || 'http://192.168.5.4:82/api/'

export default class CrmService {
	constructor() {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
	}

	// #region Login
	Login(_username, _password) {
		debugger;
		return axios.post('login/login', {'username': _username, 'password': _password}).then(res => res.data);
    }

	GetUserbyToken(_token) {
		debugger;
		return axios.post('login/GetUserbyToken', {'value': _token}).then(res => res.data);
    }
	// #endregion

	// #region Şifre
	sifreDegistir(object) {
		debugger;
		return axios.post('sifredegistir/SifreDegistir', object).then(res => res.data);
    }

	sifremiUnuttum(mail) {
		debugger;
		return axios.post('sifredegistir/SifremiUnuttum', { 'value': mail }).then(res => res.data);
    }
	// #endregion

	// #region Dashboard Yönetim
	getPersonelMarkaAdetleri(dates) {
		debugger;
		return axios.post('dashboardyonetim/GetPersonelMarkaAdetleri', {'dates': dates}).then(res => res.data);
    }

	getBolgeMarkaAdetleri(dates) {
		debugger;
		return axios.post('dashboardyonetim/GetBolgeMarkaAdetleri', {'dates': dates}).then(res => res.data);
    }

	getPersonelMarkaAdetleriKazanilan(dates) {
		debugger;
		return axios.post('dashboardyonetim/GetPersonelMarkaAdetleriKazanilan', {'dates': dates}).then(res => res.data);
    }

	getBolgeMarkaAdetleriKazanilan(dates) {
		debugger;
		return axios.post('dashboardyonetim/GetBolgeMarkaAdetleriKazanilan', {'dates': dates}).then(res => res.data);
    }

	getPersonelMarkaAdetleriKaybedilen(dates) {
		debugger;
		return axios.post('dashboardyonetim/GetPersonelMarkaAdetleriKaybedilen', {'dates': dates}).then(res => res.data);
    }

	getBolgeMarkaAdetleriKaybedilen(dates) {
		debugger;
		return axios.post('dashboardyonetim/GetBolgeMarkaAdetleriKaybedilen', {'dates': dates}).then(res => res.data);
    }
	// #endregion

	// #region Dashboard Servis
	getKonumlaraGoreServisTalepleri(dates) {
		debugger;
		return axios.post('dashboardservis/GetKonumlaraGoreServisTalepleri', {'dates': dates}).then(res => res.data);
    }

	getMarkalaraGoreServisTalepleri(dates) {
		debugger;
		return axios.post('dashboardservis/GetMarkalaraGoreServisTalepleri', {'dates': dates}).then(res => res.data);
    }

	getTurlerineGoreServisRaporlari(dates) {
		debugger;
		return axios.post('dashboardservis/GetTurlerineGoreServisRaporlari', {'dates': dates}).then(res => res.data);
    }

	getKonumlaraVeMarkalaraGoreServisTalepleri(dates) {
		debugger;
		return axios.post('dashboardservis/GetKonumlaraVeMarkalaraGoreServisTalepleri', {'dates': dates}).then(res => res.data);
    }

	getKonumlaraVeTurlereGoreServisRaporlari(dates){
		debugger;
		return axios.post('dashboardservis/GetKonumlaraVeTurlereGoreServisRaporlari', {'dates': dates}).then(res => res.data);
	}

	getOzetBilgi(dates){
		debugger;
		return axios.post('dashboardservis/GetOzetBilgi', {'dates': dates}).then(res => res.data);
	}

	// #endregion

	// #region Firma
	
	getFirmaById(ID) {
		debugger;
		return axios.post('firma/GetById', {'value': ID}).then(res => res.data);
    }

	getFirmaCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('firma/CreateUpdate', data).then(res => res.data);
	}

	getFirmaUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('firma/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region İlgili Kişi

	getIlgiliKisiById(ID) {
		debugger;
		return axios.post('ilgilikisi/GetById', {'value': ID}).then(res => res.data);
	}

	getIlgiliKisiCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('ilgilikisi/CreateUpdate', data).then(res => res.data);
	}

	getIlgiliKisiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('ilgilikisi/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Randevu

	getRandevuById(ID) {
		debugger;
		return axios.post('randevu/GetById', {'value': ID}).then(res => res.data);
	}

	getRandevuCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('randevu/CreateUpdate', data).then(res => res.data);
	}

	getRandevuUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('randevu/CreateUpdate', data).then(res => res.data);
	}

	getRandevuTamamlandiOlarakIsaretle(ID) {
		debugger;
		return axios.post('randevu/TamamlandiOlarakIsaretle', {'value': ID}).then(res => res.data);
	}

	// #endregion

	// #region Telefon Görüşmesi

	getTelefonGorusmesiById(ID) {
		debugger;
		return axios.post('telefongorusmesi/GetById', {'value': ID}).then(res => res.data);
	}

	getTelefonGorusmesiCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('telefongorusmesi/CreateUpdate', data).then(res => res.data);
	}

	getTelefonGorusmesiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('telefongorusmesi/CreateUpdate', data).then(res => res.data);
	}

	getTelefonGorusmesiTamamlandiOlarakIsaretle(ID) {
		debugger;
		return axios.post('telefongorusmesi/TamamlandiOlarakIsaretle', {'value': ID}).then(res => res.data);
	}

	// #endregion

	// #region Fırsat

	getFirsatById(ID) {
		debugger;
		return axios.post('firsat/GetById', {'value': ID}).then(res => res.data);
	}

	getFirsatCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('firsat/CreateUpdate', data).then(res => res.data);
	}

	getFirsatUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('firsat/CreateUpdate', data).then(res => res.data);
	}

	OpportunityClose(data) {
		debugger;
		return axios.post('firsat/OpportunityClose', data).then(res => res.data);
	}

	// #endregion

	// #region Teklif

	getTeklifById(ID) {
		debugger;
		return axios.post('teklif/GetById', {'value': ID}).then(res => res.data);
	}

	HizliTeklifOlustur(firsatId) {
		debugger;
		return axios.post('teklif/HizliTeklifOlustur', {'value': firsatId}).then(res => res.data);
	}

	getTeklifUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('teklif/CreateUpdate', data).then(res => res.data);
	}

	getTeklifTezgahEkle(quoteId) {
		debugger;
		return axios.post('teklif/TezgahEkle', { 'value': quoteId }).then(res => res.data);
	}

	TeklifUrunleriCreateUpdate(data) {
		debugger;
		return axios.post('teklif/TeklifUrunleriCreateUpdate', data).then(res => res.data);
	}

	TeklifiEtkinlestir(quoteId) {
		debugger;
		return axios.post('teklif/FiyatTeklifiniEtkinlestir', { 'value': quoteId }).then(res => res.data);
	}

	SiparisOlustur(data) {
		debugger;
		return axios.post('teklif/SiparisOlustur', data).then(res => res.data);
	}

	TeklifKaybedildi(data) {
		debugger;
		return axios.post('teklif/FiyatTeklifiKaybedildi', data).then(res => res.data);
	}

	FiyatTeklifiYenidenAc(quoteId) {
		debugger;
		return axios.post('teklif/FiyatTeklifiYenidenAc', { 'value': quoteId }).then(res => res.data);
	}
	

	// #endregion

	// #region Sipariş

	getSiparisById(ID) {
		debugger;
		return axios.post('siparis/GetById', {'value': ID}).then(res => res.data);
	}

	getSiparisUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('siparis/CreateUpdate', data).then(res => res.data);
	}

	updateSalesOrderDetail(data){
		return axios.post('siparis/UpdateSalesOrderDetail', data).then(res => res.data);
	}

	createTezgah(ID) {
		debugger;
		return axios.post('siparis/CreateTezgah', {'value': ID}).then(res => res.data);
	}

	// #endregion

	// #region Yedek Parça

	getYedekParcaById(ID) {
		debugger;
		return axios.post('YedekParca/GetById', {'value': ID}).then(res => res.data);
	}

	getYedekParcaCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('YedekParca/CreateUpdate', data).then(res => res.data);
	}

	getYedekParcaUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('YedekParca/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Yedek Parça Teklif / Sipariş

	getYedekParcaTeklifSiparisById(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/GetById', {'value': ID}).then(res => res.data);
	}

	getTeklifGetYedekParca(searchValue) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/GetTeklifGetYedekParca', {'value': searchValue}).then(res => res.data);
	}

	getTeklifGetYedekParca_Alternatif(searchValue) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/GetTeklifGetYedekParca_Alternatif', {'value': searchValue}).then(res => res.data);
	}

	getYedekParcaTeklifSiparisCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('YedekParcaTeklifSiparis/CreateUpdate', data).then(res => res.data);
	}

	getYedekParcaTeklifSiparisUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('YedekParcaTeklifSiparis/CreateUpdate', data).then(res => res.data);
	}

	updateSiparisiGeriAlOpsiyonlariTemizle(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/UpdateSiparisiGeriAlOpsiyonlariTemizle', {'value': ID}).then(res => res.data);
	}

	getYedekParcaTeklifSiparisUrunleriCreate(data) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/CreateYedekParcaTeklifSiparisUrunu', data).then(res => res.data);
	}

	getYedekParcaTeklifSiparisUrunleriDelete(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/DeleteYedekParcaTeklifSiparisUrunu', {'value': ID}).then(res => res.data);
	}

	getYedekParcaTeklifSiparisUrunleriTopluDelete(data) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/DeleteYedekParcaTeklifSiparisUrunleri', data).then(res => res.data);
	}

	getYedekParcaTeklifSiparisUrunuUpdate(data){
		return axios.post('YedekParcaTeklifSiparis/YedekParcaTeklifSiparisUrunuUpdate', data).then(res => res.data);
	}

	getYedekParcaSorgulaCoklu(yedekParcaList){
		return axios.post('YedekParcaTeklifSiparis/GetYedekParcaSorgulaCoklu', {'value': yedekParcaList}).then(res => res.data);
	}

	updateDurumFiyatIstendi(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/UpdateDurumFiyatIstendi', {'value': ID}).then(res => res.data);
	}

	updateDurumSiparisOnaylandi(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/UpdateDurumSiparisOnaylandi', {'value': ID}).then(res => res.data);
	}

	updateDurumMusteriyeTeklifGonderildi(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/UpdateDurumMusteriyeTeklifGonderildi', {'value': ID}).then(res => res.data);
	}

	updateDurumTeklifRevize(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/UpdateDurumTeklifRevize', {'value': ID}).then(res => res.data);
	}

	createSiparisOlustur(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/CreateSiparisOlustur', {'value': ID}).then(res => res.data);
	}

	getServisRaporlariYedekParcali(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/GetServisRaporlariYedekParcali', {'value': ID}).then(res => res.data);
	}

	getServisRaporundanYedekParca(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/GetServisRaporundanYedekParca', {'value': ID}).then(res => res.data);
	}

	muhasebeyeMailGonder(ID) {
		debugger;
		return axios.post('YedekParcaTeklifSiparis/MuhasebeyeMailGonder', {'value': ID}).then(res => res.data);
	}

	// #endregion

	// #region Servis Talebi

	getServisTalebiById(ID) {
		debugger;
		return axios.post('ServisTalebi/GetById', {'value': ID}).then(res => res.data);
	}

	getServisTalebiCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('ServisTalebi/CreateUpdate', data).then(res => res.data);
	}

	getServisTalebiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('ServisTalebi/CreateUpdate', data).then(res => res.data);
	}

	getServisPersonelByUserId() {
		debugger;
		return axios.get('ServisTalebi/GetServisPersonelByUserId').then(res => res.data);
	}

	sendGorevlendirmeYazisi(ID){
		debugger;
		return axios.post('ServisTalebi/SendGorevlendirmeYazisi', {'value': ID}).then(res => res.data);
	}

	sendMaliyetBilgisi(ID){
		debugger;
		return axios.post('ServisTalebi/SendMaliyetBilgisi', {'value': ID}).then(res => res.data);
	}

	getServisTalebiniCoz(ID) {
		debugger;
		return axios.post('ServisTalebi/CloseResolve', {'value': ID}).then(res => res.data);
	}

	getServisTalebiniIptalEt(ID) {
		debugger;
		return axios.post('ServisTalebi/CloseCancel', {'value': ID}).then(res => res.data);
	}

	getServisTalebiniYenidenEtkinlestir(ID) {
		debugger;
		return axios.post('ServisTalebi/Reactive', {'value': ID}).then(res => res.data);
	}

	// #endregion

	// #region Servis Raporu

	getServisRaporuById(ID) {
		debugger;
		return axios.post('ServisRaporu/GetById', {'value': ID}).then(res => res.data);
	}

	getServisRaporuCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('ServisRaporu/CreateUpdate', data).then(res => res.data);
	}

	getServisRaporuUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('ServisRaporu/CreateUpdate', data).then(res => res.data);
	}

	getCreateUpdateCalismaSaati(data) {
		debugger;
		return axios.post('ServisRaporu/CreateUpdateCalismaSaati', data).then(res => res.data);
	}

	getCreateUpdateParca(data) {
		debugger;
		return axios.post('ServisRaporu/CreateUpdateParca', data).then(res => res.data);
	}

	getServisRaporuTamamla(ID) {
		debugger;
		return axios.post('ServisRaporu/ServisRaporuTamamla', {'value': ID}).then(res => res.data);
	}

	getServisRaporunuYenidenEtkinlestir(ID) {
		debugger;
		return axios.post('ServisRaporu/Reactive', {'value': ID}).then(res => res.data);
	}

	getServisMaliyetiById(ID) {
		debugger;
		return axios.post('ServisRaporu/GetServisMaliyetiById', {'value': ID}).then(res => res.data);
	}

	getServisMaliyetiUpdate(data) {
		debugger;
		return axios.post('ServisRaporu/ServisMaliyetiUpdate', data).then(res => res.data);
	}

	getServisMaliyetiOlustur(ID) {
		debugger;
		return axios.post('ServisRaporu/ServisMaliyetiOlustur', {'value': ID}).then(res => res.data);
	}

	getServisMaliyetiSil(ID) {
		debugger;
		return axios.post('ServisRaporu/ServisMaliyetiSil', {'value': ID}).then(res => res.data);
	}
	
	
	// #endregion

	// #region Sözleşme

	getSozlesmeById(ID) {
		debugger;
		return axios.post('sozlesme/GetById', {'value': ID}).then(res => res.data);
	}

	getSozlesmeCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('sozlesme/CreateUpdate', data).then(res => res.data);
	}

	getSozlesmeUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('sozlesme/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Sözleşme Satırı

	getSozlesmeSatiriById(ID) {
		debugger;
		return axios.post('sozlesmesatiri/GetById', {'value': ID}).then(res => res.data);
	}

	getSozlesmeSatiriCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('sozlesmesatiri/CreateUpdate', data).then(res => res.data);
	}

	getSozlesmeSatiriUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('sozlesmesatiri/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Rakip Ünite

	getRakipUniteById(ID) {
		debugger;
		return axios.post('rakipunite/GetById', {'value': ID}).then(res => res.data);
	}

	getRakipUniteCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('rakipunite/CreateUpdate', data).then(res => res.data);
	}

	getRakipUniteUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('rakipunite/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Ürün

	getUrunById(ID) {
		debugger;
		return axios.post('urun/GetById', {'value': ID}).then(res => res.data);
	}

	updateProductPriceLevel(data){
		debugger;
		return axios.post('urun/UpdateProductPriceLevel', data).then(res => res.data);
	}

	// #endregion

	// #region Tezgah

	getTezgahById(ID) {
		debugger;
		return axios.post('tezgah/GetById', {'value': ID}).then(res => res.data);
	}

	getTezgahCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('tezgah/CreateUpdate', data).then(res => res.data);
	}

	getTezgahUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('tezgah/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Tezgah Opsiyonları

	getTezgahOpsiyonlariById(ID) {
		debugger;
		return axios.post('tezgahopsiyonlari/GetById', {'value': ID}).then(res => res.data);
	}

	getTezgahOpsiyonlariCreateUpdate(data) {
		debugger;
		return axios.post('tezgahopsiyonlari/CreateUpdate', data).then(res => res.data);
	}

	updateOpsiyonlar(data) {
		debugger;
		return axios.post('tezgahopsiyonlari/UpdateOpsiyonlar', data).then(res => res.data);
	}

	// #endregion

	// #region Tesis Ekipman

	getTesisEkipmanById(ID) {
		debugger;
		return axios.post('TesisEkipman/GetById', {'value': ID}).then(res => res.data);
	}

	getTesisEkipmanCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('TesisEkipman/CreateUpdate', data).then(res => res.data);
	}

	getTesisEkipmanUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('TesisEkipman/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Hedefler

	getHedeflerAll(year) {
		debugger;
		return axios.get('hedefler/GetAll?year=' + year).then(res => res.data);
	}

	// #endregion

	// #region Otomasyon Projesi

	getOtomasyonProjesiById(ID) {
		debugger;
		return axios.post('otomasyonprojesi/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonProjesiCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonprojesi/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonProjesiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonprojesi/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Otomasyon Teklifi

	getOtomasyonTeklifiById(ID) {
		debugger;
		return axios.post('otomasyonteklif/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonTeklifiCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonteklif/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonTeklifiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonteklif/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonTeklifiSatisaCevir(ID) {
		debugger;
		return axios.post('otomasyonteklif/TeklifiSatisaCevir', {'value': ID}).then(res => res.data);
	}

	getOtomasyonTeklifiIptal(ID) {
		debugger;
		return axios.post('otomasyonteklif/TeklifIptal', {'value': ID}).then(res => res.data);
	}
	// #endregion

	// #region Otomasyon Satışı

	getOtomasyonSatisiById(ID) {
		debugger;
		return axios.post('otomasyonsatis/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonSatisiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonsatis/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Otomasyon Tasarım

	getOtomasyonTasarimById(ID) {
		debugger;
		return axios.post('otomasyontasarim/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonTasarimCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyontasarim/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonTasarimUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyontasarim/CreateUpdate', data).then(res => res.data);
	}

	// #endregion
	
	// #region Otomasyon Imalat

	getOtomasyonImalatById(ID) {
		debugger;
		return axios.post('otomasyonimalat/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonImalatCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonimalat/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonImalatUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonimalat/CreateUpdate', data).then(res => res.data);
	}

	// #endregion
		
	// #region Otomasyon Ekipman Talebi

	getOtomasyonEkipmanTalebiById(ID) {
		debugger;
		return axios.post('otomasyonekipmantalebi/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonEkipmanTalebiCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonekipmantalebi/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonEkipmanTalebiUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonekipmantalebi/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonSatinalmayaAktar(data) {
		debugger;
		return axios.post('otomasyonekipmantalebi/SatinalmayaAktar', data).then(res => res.data);
	}

	// #endregion

	// #region Otomasyon Satın Alma

	getOtomasyonSatinAlmaById(ID) {
		debugger;
		return axios.post('otomasyonsatinalma/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonSatinAlmaCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonsatinalma/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonSatinAlmaUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonsatinalma/CreateUpdate', data).then(res => res.data);
	}

	// #endregion
		
	// #region Otomasyon Kurulum

	getOtomasyonKurulumById(ID) {
		debugger;
		return axios.post('otomasyonkurulum/GetById', {'value': ID}).then(res => res.data);
	}

	getIlkKurulumKaydi(ID) {
		debugger;
		return axios.post('otomasyonkurulum/GetIlkKurulumKaydi', {'value': ID}).then(res => res.data);
	}

	getOtomasyonKurulumCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonkurulum/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonKurulumUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonkurulum/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Otomasyon Servis

	getOtomasyonServisById(ID) {
		debugger;
		return axios.post('otomasyonservis/GetById', {'value': ID}).then(res => res.data);
	}

	getOtomasyonServisCreate(data) {
		debugger;
		data["crudType"] = "create";
		return axios.post('otomasyonservis/CreateUpdate', data).then(res => res.data);
	}

	getOtomasyonServisUpdate(data) {
		debugger;
		data["crudType"] = "update";
		return axios.post('otomasyonservis/CreateUpdate', data).then(res => res.data);
	}

	// #endregion

	// #region Global

	GetEntityById(options) {
		debugger;
		return axios.post('global/GetEntityById', options).then(res => res.data);
	}

	DeleteRecordById(entityName, entityId, yetkiEntityName) {
		debugger;
		return axios.post('global/DeleteRecord', {'entityName': entityName, 'entityId': entityId, 'yetkiEntityName': yetkiEntityName}).then(res => res.data);
	}

	DeleteRecordList(data) {
		debugger;
		return axios.post('global/DeleteRecordList', data).then(res => res.data);
	}

	CheckAccessPrivilegeRecordById(entityName, entityId) {
		debugger;
		return axios.post('global/CheckAccessPrivilegeRecord', {'entityName': entityName, 'entityId': entityId}).then(res => res.data);
	}

	ChangeStateStatus(data) {
		debugger;
		return axios.post('global/changeStateStatus', data).then(res => res.data);
	}

	GetAnnotationList(ID) {
		debugger;
		return axios.post('global/GetAnnotationList', {'value': ID}).then(res => res.data);
	}

	CreateAnnotationFile(formData, postData) {
		debugger;
		return axios.post('global/CreateAnnotationFile',
							formData, {
								headers: {
									'Content-Type': 'multipart/form-data; charset=UTF-8',
									'objectTypeCode': postData.objectTypeCode,
									'entityName': postData.logicalName,
									'objectId': postData.objectId,
									'subject': encodeURIComponent(postData.subject),
									'noteText': encodeURIComponent(postData.noteText) }
								}).then(res => res.data);
	}

	DeleteUploadFile(filePath) {
		debugger;
		return axios.post('global/DeleteUploadFile', {'value': filePath}).then(res => res.data);
	}

	CreateUploadFile(formData, postData){
		debugger;
		return axios.post('global/CreateUploadFile',
							formData, {
								headers: {
									'Content-Type': 'multipart/form-data; charset=UTF-8',
									'entityName': postData.logicalName,
									'objectId': postData.objectId }
								}).then(res => res.data);
	}

	GetMobilKullanicilar() {
		debugger;
		return axios.get('global/GetMobilKullanicilar').then(res => res.data);
	}

	GetMobilKullanicilarOtomasyon() {
		debugger;
		return axios.get('global/GetMobilKullanicilarOtomasyon').then(res => res.data);
	}

	GetMobilKullanicilarOtomasyonKurulum(data) {
		debugger;
		return axios.post('global/GetMobilKullanicilarOtomasyonKurulum', data).then(res => res.data);
	}

	GetOtomasyonAraclar() {
		debugger;
		return axios.get('global/GetOtomasyonAraclar').then(res => res.data);
	}

	GetResources() {
		debugger;
		return axios.get('global/GetResources').then(res => res.data);
	}

	GetSubjects() {
		debugger;
		return axios.get('global/GetSubjects').then(res => res.data);
    }

	GetAttributeListByEntityName(entityName) {
		debugger;
		return axios.post('global/GetAttributeListByEntityName', {'value': entityName}).then(res => res.data);
	}

	GetAdvancedFindSearch(data) {
		debugger;
		return axios.post('global/AdvancedFindSearch', data).then(res => res.data);
	}

	getPanoModulleri(){
		return axios.get('global/getPanoModulleri').then(res => res.data);
	}

	getStringMaps() {
		return axios.get('global/getstringmap').then(res => res.data);
    }

	GetEntityLookup(_entityName, _nameField, _searchValue, _parentFieldName, _parentId, _state, _secondNameField, _secondNameFieldType) {
		debugger;
		return axios.post('global/GetEntityLookup', {
			'entityName' : _entityName,
			'nameField' : _nameField,
			'searchValue' : _searchValue,
			'parentFieldName' : _parentFieldName,
			'parentId' : _parentId,
			'state' : _state,
			'secondNameField' : _secondNameField,
			'secondNameFieldType' : _secondNameFieldType
		}).then(res => res.data);
    }

	GetSsrsReport(reportName, reportParams, fileType){
		debugger;
		return axios.post('global/GetSsrsReport',
							{
								'reportName': reportName,
								'reportParams': reportParams,
								'fileType': fileType
							}, {responseType: "blob"}).then(res => res);
	}

	GetSsrsReportWithExternalFiles(reportName, reportParams, fileType, externalFiles){
		debugger;
		return axios.post('global/GetSsrsReportWithExternalFiles',
							{
								'reportName': reportName,
								'reportParams': reportParams,
								'fileType': fileType,
								'externalFiles': externalFiles
							}, {responseType: "blob"}).then(res => res);
	}

	GetViewData(lazyParams) {
		debugger;
		return axios.post('global/GetViewData', lazyParams).then(res => res.data);
    }

	GetSavedViewsUser(entityName) {
		debugger;
		return axios.post('global/GetSavedViewsUser', {'value': entityName}).then(res => res.data);
    }

	GetSavedViewsSystem(entityName) {
		debugger;
		return axios.post('global/GetSavedViewsSystem', {'value': entityName}).then(res => res.data);
    }
	
	// #endregion

}